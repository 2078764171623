import React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import { media } from '../components/helpers';
import styled from 'styled-components';
import Layout from '../components/layout';
import Meta from '../components/meta';

const FullscreenBanner = styled.div`
	position: relative;
	.gatsby-image-wrapper {
		max-height: 100vh;
		height: 70vh;
		overflow: hidden;

		img {
			object-position: top !important;
		}
	}
	.overlay {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.45);
	}

	.content-wrap {
		position: absolute;
		top: 45%;
		width: 100%;
		text-align: center;
		z-index: 1000;
		padding: 0px 20px;

		${media.medium`top: 39%;`};
		${media.xl`top: 40%;`};

		h1 {
			color: #fff;
			margin: 0px;
			font-size: 19px;
			line-height: 35px;
			font-weight: 300;
			${media.medium`font-size: 3.25rem;line-height: 80px; line-height: 70px;`};
			span {
				display: block;
			}
		}
	}
`;

const PreviousClients = styled.div`
	background: #fff;
	padding: 50px 0px;
	.title-wrap {
		text-align: center;
		h2 {
			color: #222;
			margin: 0px;
			font-weight: 300;
			letter-spacing: 0.5px;
		}
	}

	.logo-grid {
		display: flex;
		flex-wrap: wrap;
		max-width: 80vw;
		margin: 40px auto 0px;
		.logo {
			flex: 1 1 100%;
			align-self: center;
			margin-bottom: 40px;
			${media.small`flex: 1 1 50%;`};
			${media.medium`flex: 1 1 20%;`};

			.gatsby-image-wrapper {
				max-width: 200px;
				margin: 0 auto;
			}
		}
	}
`;

const Fade = styled.div`
	background: linear-gradient(to bottom, transparent, #222);
	height: 150px;
	margin-top: -150px;
	position: absolute;
	z-index: 1500;
	width: 100%;
	display: none;

	${media.medium`display: block;`};
`;
const SliderWrap = styled.div`
	max-width: 700px;
	margin: 0 auto 40px;
	padding: 0px 20px;
	${media.medium`padding: 0px;`};

	div {
		&:focus,
		&:active {
			outline: 0;
		}
	}

	p {
		font-size: 21px;
		line-height: 40px;
		font-weight: 300;

		span {
			display: block;
			font-weight: bold;
			margin-top: 20px;
			text-align: center;
		}
	}
`;

class SimpleSlider extends React.Component {
	render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000
		};
		return (
			<SliderWrap>
				<Slider {...settings}>
					<div>
						<p>
							"Liz is a talented marketing executive. I have seen first-hand Liz
							get unbelievable results in a product launch, as well as other
							great marketing results. I wouldn't hesitate to recommend Liz to
							companies seeking marketing or branding leadership or support."{' '}
							<span>Barry Carr — Managing Partner, Carr &amp; Ferrell LLP</span>
						</p>
					</div>
					<div>
						<p>
							"Liz’s creative thinking, positive can-do attitude and drive as a
							marketing professional make her an absolute pleasure to work with.
							Every project was unique and had a specific direction and focus
							that made it stand out. Liz continually delivers results. I
							recommend her highly as a valuable contributor for any marketing
							team." <span>Todd Marquez — VP of Sales, Light Reading</span>
						</p>
					</div>
					<div>
						<p>
							"Liz has a special talent for providing marketing messages for
							complex products in a simplified manner that focuses on the heart
							of how these companies fit into the ecosystem. She has brought new
							companies to market and prepared others for acquisitions. I highly
							recommend her as a skilled marketer and a valued partner."{' '}
							<span>
								Cathy Hetzel — Executive Vice President, comScore, Inc.
							</span>
						</p>
					</div>
				</Slider>
			</SliderWrap>
		);
	}
}

const IndexPage = ({ data }) => {
	const banner = data.banner.childImageSharp;
	const hbo = data.hbo.childImageSharp;
	const moto = data.moto.childImageSharp;
	const sprint = data.sprint.childImageSharp;
	// const open = data.open.childImageSharp;

	return (
		<Layout>
			<Meta
				title="Davidoff Consulting Group SF | Expert Marketing Support When You Need It Most"
				description="Davidoff Consulting Group provides Thought Leadership and Marketing Expertise for High Growth and Innovative Companies in the Television, Video and Interactive Media industries."
			/>
			<div className="home">
				<FullscreenBanner>
					<Img fluid={banner.fluid} />
					<div className="content-wrap">
						<h1>
							Expert Marketing Support <span>When You Need It Most </span>
						</h1>
					</div>
					<div className="overlay" />
				</FullscreenBanner>
				<Fade />
				<PreviousClients>
					<div className="title-wrap">
						<h2>Previous experience:</h2>
					</div>

					<div className="logo-grid">
						<div className="logo">
							<Img fluid={sprint.fluid} />
						</div>
						<div className="logo">
							<Img fluid={moto.fluid} />
						</div>
						<div className="logo">
							<Img fluid={hbo.fluid} />
						</div>
					</div>
				</PreviousClients>
				<SimpleSlider />
			</div>
		</Layout>
	);
};

export default IndexPage;

export const IndexQuery = graphql`
	query {
		banner: file(relativePath: { eq: "download.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1920, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		sprint: file(relativePath: { eq: "sprint-new.png" }) {
			childImageSharp {
				fluid(maxWidth: 300, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}

		moto: file(relativePath: { eq: "moto.png" }) {
			childImageSharp {
				fluid(maxWidth: 300, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		hbo: file(relativePath: { eq: "hbo.png" }) {
			childImageSharp {
				fluid(maxWidth: 300, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
